import complainTypeFromStore from '@/modules/complain-type/store/complain-type-form-store';
import complainTypeListStore from '@/modules/complain-type/store/complain-type-list-store';

export default {
    namespaced: true,

    modules: {
        form: complainTypeFromStore,
        list: complainTypeListStore,
    },
};
